<template>
  <div>
    <BaseLayoutTitle
      v-if="item"
      :prefix="$t(`inventory.categories.${item.data.attributes.name}`)"
      :title="$t('common.details')"
    >
      <template v-slot:edit>
        <CloneItemButton
          v-if="
            (itemCategory && itemCategory === 'prospect') ||
              !item.data.attributes.itemUnitsInStock
          "
          :item-id="inventoryItemId"
        />
      </template>
    </BaseLayoutTitle>
    <v-row>
      <v-col cols="12"> <InventoryItemDetails /></v-col>
      <v-col cols="12">
        <InventoryItemHistory
          v-if="itemResource"
          :resource="itemResource"
          :item-group="itemGroup"
          :purchase-order="purchaseOrder"
          :is-loading="isLoading"
        />
      </v-col>
      <v-col v-if="isAdmin" cols="12">
        <DocumentIndex :resource-id="inventoryItemId" resource-type="Item"
      /></v-col>
    </v-row>
  </div>
</template>
<script>
import InventoryItemDetails from './components/InventoryItemDetails'
import InventoryItemHistory from './components/InventoryItemHistory'
import DocumentIndex from '@/views/document/components/DocumentIndex'
import { mapGetters } from 'vuex'
import CloneItemButton from './components/CloneItemButton.vue'
export default {
  name: 'InventoryItemShow',
  components: {
    InventoryItemHistory,
    InventoryItemDetails,
    DocumentIndex,
    CloneItemButton
  },
  props: {
    inventoryItemId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemResource: null,
      itemGroup: null,
      purchaseOrder: null,
      isLoading: false,
      itemCategory: null
    }
  },
  computed: {
    ...mapGetters({ item: 'inventoryItem/item', isAdmin: 'auth/isAdmin' })
  },
  async created() {
    this.isLoading = true
    await this.fetchData()
    this.isLoading = false
  },
  methods: {
    async fetchData() {
      const item = await this.fetchItem(this.inventoryItemId)
      if (!this.isAdmin) return
      if (item.data.attributes.clonedFromId) return
      const resource = item.data.attributes.customAttributes?.resource
      if (resource) {
        this.itemResource = await this.fetchResource(resource)
      } else this.itemResource = await this.fetchResource(item.data.id)

      if (!this.itemResource) return

      const quotationId = this.itemResource.data.relationships.parent.data?.id
      const itemQuotation = await this.fetchItemQuotation(quotationId)
      const { orderId } = itemQuotation.data.attributes
      this.purchaseOrder = await this.fetchPurchaseOrder(orderId)
      const itemGroupId = this.itemResource.data.relationships.itemGroup.data
        ?.id
      this.itemGroup = await this.fetchItemGroupLot(itemGroupId)
    },
    async fetchItem(itemId) {
      const item = await this.$store.dispatch('inventoryItem/fetch', itemId)
      this.itemCategory = item.data.attributes.category
      return item
    },
    async fetchResource(id) {
      if (id)
        return await this.$store.dispatch('inventoryItem/fetchResource', id)
    },
    async fetchItemQuotation(id) {
      if (id) return await this.$store.dispatch('itemQuotation/fetch', id)
    },
    async fetchItemGroupLot(id) {
      if (id) return await this.$store.dispatch('itemGroupLot/fetch', id)
    },
    async fetchPurchaseOrder(id) {
      if (id) return await this.$store.dispatch('purchaseOrder/fetch', id)
    }
  }
}
</script>
