<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary"
        depressed
        v-bind="attrs"
        class="text-capitalize"
        v-on="on"
      >
        Restaurar
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="">
        <v-icon left color="warning">mdi-alert-circle</v-icon> Restaurar
        Artículo
      </v-card-title>

      <v-card-text>
        <p>
          Realizar esta operación solamente cuando sea necesario recuperar un
          artículo.
        </p>

        <p>
          Por favor, confirme el número de unidades que se debieron crear
        </p>
        <v-text-field
          v-model.number="item.attributes.quantity"
          class="mt-5"
          outlined
          label="Unidades"
          type="number"
          step="1"
        >
        </v-text-field
      ></v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :loading="isLoading" @click="onClick">
          {{ $t('actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { INVENTORY_ITEM } from '@/models/inventory.item'
export default {
  props: {
    itemId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      item: INVENTORY_ITEM()
    }
  },
  methods: {
    ...mapActions({
      fecthInventoryItem: 'inventoryItem/fetch',
      createInventoryItem: 'inventoryItem/create',
      updateInventoryItem: 'inventoryItem/update'
    }),
    async onClick() {
      this.isLoading = true
      const item = await this.fecthInventoryItem(this.itemId)
      this.clone(item)
      await this.createInventoryItem(this.item)
      this.isLoading = false
      this.dialog = false
    },

    clone(item) {
      const {
        data: {
          id: itemId,
          attributes: {
            customAttributes,
            dimensions,
            description,
            dimensionsUnit,
            name,
            organizationId,
            supplierId,
            volume,
            volumeUnit,
            itemUnitsCount,
            category,
            facilityId
          },
          relationships: {
            itemGroup: {
              data: { id: itemGroupId }
            },
            parent: {
              data: { id: parentId }
            }
          }
        }
      } = item
      Object.assign(this.item.attributes, {
        category,
        customAttributes,
        dimensions,
        description: description + '(R)',
        dimensionsUnit,
        facilityId,
        itemGroupId,
        parentId,
        name,
        organizationId,
        supplierId,
        volume,
        volumeUnit,
        quantity: itemUnitsCount,
        clonedFromId: itemId
      })
    }
  }
}
export const a = {
  data: {
    id: 'd1debcec-90c8-4db9-a903-e1c166f007fb',
    type: 'item',
    attributes: {
      organizationId: '0a5f2bae-cc14-4b41-8556-0ce19848e4b8',
      facilityId: '6a72464d-6d7b-4bb5-a753-093a67fe2de2',
      sellingPrice: '0.0',
      sellingPriceCurrency: 'EUR',
      costPrice: '0.0',
      costPriceCurrency: 'EUR',
      sellingPriceDetails: { amount: '0.0', cents: 0, currency: 'EUR' },
      costPriceDetails: { amount: '0.0', cents: 0, currency: 'EUR' },
      name: 'log',
      orderId: null,
      clientId: null,
      tax: null,
      description: 'Q-3887/1A',
      supplierId: null,
      systemNumbers: '',
      systemNumbersUnit: '',
      itemUnitsCount: 1,
      itemUnitsInStockCount: 0,
      itemUnitsOutOfStockCount: 1,
      itemUnitsProcessingPendingCount: 0,
      itemUnitsResponseCount: 1,
      status: 'out_of_stock',
      category: 'resource',
      customAttributes: {
        type: 'W1.1',
        ncCode: '4403',
        origin: 'Republic of Congo',
        specie: { text: 'Sapele', value: 'Entandrophragma cylindricum' },
        marking: '06517',
        quality: 'LM',
        category: 'FSC 100%',
        resource: 'd1debcec-90c8-4db9-a903-e1c166f007fb',
        blockchain: null,
        instructions: '',
        incomingShipment: 'c48f5445-580b-4418-a289-a1e625a35baf'
      },
      volume: '9.58',
      weight: null,
      dimensions: { width: null, height: null, length: 9.9, diameter: 1.11 },
      volumeUnit: 'm³',
      weightUnit: '',
      dimensionsUnit: 'm',
      pendingItemUnitsCount: 0,
      clonedFromId: null,
      createdAt: '2021-03-24T14:03:52.208Z',
      updatedAt: '2021-04-07T15:24:44.272Z'
    },
    relationships: {
      facility: {
        data: { id: '6a72464d-6d7b-4bb5-a753-093a67fe2de2', type: 'facility' }
      },
      itemGroup: {
        data: { id: 'a350dcc1-c2bd-472d-9619-45832c660ac0', type: 'itemGroup' }
      },
      parent: {
        data: { id: '3939bc2f-298c-4daf-a35f-4f6bca04a337', type: 'item' }
      },
      children: { data: [] }
    }
  },
  meta: {
    userInfo: {
      userId: 'e6b26b07-9134-4426-9eb8-632c9383fde8',
      organizationId: '0a5f2bae-cc14-4b41-8556-0ce19848e4b8',
      roles: ['admin']
    }
  },
  included: [
    {
      id: 'a350dcc1-c2bd-472d-9619-45832c660ac0',
      type: 'itemGroup',
      attributes: {
        organizationId: '0a5f2bae-cc14-4b41-8556-0ce19848e4b8',
        clientId: null,
        name: 'lot',
        category: 'lot',
        subCategory: null,
        description: 'C7/21',
        itemsCount: 13,
        merchandiseIds: [],
        customAttributes: { yield: null, profit: null },
        createdAt: '2021-04-07T14:36:18.652Z',
        updatedAt: '2021-04-07T14:36:18.652Z'
      },
      relationships: {
        items: {
          data: [
            { id: '98b92bc3-28b3-4c0b-9a48-3329009027fa', type: 'item' },
            { id: 'a7f8414c-3448-407d-945b-717e4619dd62', type: 'item' },
            { id: 'd1debcec-90c8-4db9-a903-e1c166f007fb', type: 'item' },
            { id: '58b5a9e9-d2ce-41b0-8d83-881a3fce1477', type: 'item' },
            { id: '5bd08460-34cc-4233-833b-07e7e4d4e201', type: 'item' },
            { id: '12aa20f5-caf2-49eb-b46c-c6b964d2c87a', type: 'item' },
            { id: '5dd879ba-ef5b-4961-9175-f94d37e28efb', type: 'item' },
            { id: '06381156-62e0-4ff4-bd42-28c8e2facf0c', type: 'item' },
            { id: 'd7b15f8a-5138-4f83-8d3c-070985018cc2', type: 'item' },
            { id: '6ade39f9-58c2-4bb5-bdc7-caebefe7bb1c', type: 'item' },
            { id: '157f217d-9265-43ea-bd8c-6ac3eb94903e', type: 'item' },
            { id: '3fe2f77e-9855-411c-86b0-a69963fc6bc1', type: 'item' },
            { id: '8aae86a4-766d-4402-a7e3-d14e0ae77ed4', type: 'item' }
          ]
        },
        facility: {
          data: { id: '6a72464d-6d7b-4bb5-a753-093a67fe2de2', type: 'facility' }
        }
      }
    },
    {
      id: '3939bc2f-298c-4daf-a35f-4f6bca04a337',
      type: 'parent',
      attributes: {
        organizationId: '0a5f2bae-cc14-4b41-8556-0ce19848e4b8',
        facilityId: null,
        sellingPrice: '0.0',
        sellingPriceCurrency: 'EUR',
        costPrice: '0.0',
        costPriceCurrency: 'EUR',
        sellingPriceDetails: { amount: '0.0', cents: 0, currency: 'EUR' },
        costPriceDetails: { amount: '0.0', cents: 0, currency: 'EUR' },
        name: null,
        orderId: 'c48f5445-580b-4418-a289-a1e625a35baf',
        clientId: null,
        tax: null,
        description: 'Volume requested',
        supplierId: '16faaf12-5460-4e18-8893-691ea3ee5269',
        systemNumbers: '',
        systemNumbersUnit: '',
        itemUnitsCount: 1,
        status: 'pending',
        category: 'quotation',
        customAttributes: {
          type: 'W1.1',
          ncCode: '4403',
          origin: 'Republic of Congo',
          specie: { text: 'Sapele', value: 'Entandrophragma cylindricum' },
          category: 'FSC 100%'
        },
        volume: '100.0',
        weight: null,
        dimensions: { width: null, height: null, length: null, diameter: null },
        volumeUnit: 'm³',
        weightUnit: '',
        dimensionsUnit: 'mm',
        createdAt: '2021-03-24T13:59:03.762Z',
        updatedAt: '2021-04-19T12:10:21.192Z'
      }
    }
  ]
}
</script>
