<template>
  <v-card v-if="inventoryItem" outlined>
    <v-card-title class="text-h6"
      ><v-icon left>mdi-details</v-icon
      >{{ $t(`inventory.categories.${itemName}`) }}
      {{ $t('common.details') }}</v-card-title
    >
    <v-divider></v-divider>
    <v-list>
      <v-list-item
        ><v-list-item-content class="font-weight-bold">
          {{ $t('item.description') }}
        </v-list-item-content>
        <v-list-item-content>
          {{ inventoryItem.data.attributes.description }}</v-list-item-content
        ></v-list-item
      >
      <v-list-item
        ><v-list-item-content class="font-weight-bold">
          {{ $t('item.initialStock') }}
        </v-list-item-content>
        <v-list-item-content>
          {{ itemUnitsCount }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        ><v-list-item-content class="font-weight-bold"
          >{{ $t('item.currentStock') }}
        </v-list-item-content>
        <v-list-item-content>
          {{ inStock }} of {{ itemUnitsCount }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        ><v-list-item-content class="font-weight-bold"
          >{{ $t('item.stockBeingProcessed') }}
        </v-list-item-content>
        <v-list-item-content>
          {{ itemUnitsInProcessCount }}
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        ><v-list-item-content class="font-weight-bold">
          {{ $t('item.dimensions') }}
        </v-list-item-content>
        <v-list-item-content>
          <!-- <ul class="pa-0">
            <li v-if="inventoryItem.data.attributes.dimensions.length">
              <span class="text-caption grey--text"> L</span>
              {{
                toMeters(
                  inventoryItem.data.attributes.dimensions.length,
                  inventoryItem.data.attributes.dimensionsUnit
                ) || '·'
              }}
              m
            </li>
            <li v-if="inventoryItem.data.attributes.dimensions.width">
              <span class="text-caption grey--text"> W </span>
              {{
                toCentimeters(
                  inventoryItem.data.attributes.dimensions.width,
                  inventoryItem.data.attributes.dimensionsUnit
                ) || '·'
              }}
              cm
            </li>
            <li v-if="inventoryItem.data.attributes.dimensions.height">
              <span class="text-caption grey--text"> T </span>
              {{
                toMillimeters(
                  inventoryItem.data.attributes.dimensions.height,
                  inventoryItem.data.attributes.dimensionsUnit
                ) || '·'
              }}
              mm
            </li>
            <li v-if="inventoryItem.data.attributes.dimensions.diameter">
              <span class="grey--text"> ⌀ </span>
              {{
                toCentimeters(
                  inventoryItem.data.attributes.dimensions.diameter,
                  inventoryItem.data.attributes.dimensionsUnit
                ) || '·'
              }}
              cm
            </li>
            <li v-if="inventoryItem.data.attributes.volume">
              <span class="grey--text"> Vol. </span>
              {{ inventoryItem.data.attributes.volume || '·' }}
              <span
                v-if="
                  inventoryItem.data.attributes.volume &&
                    inventoryItem.data.attributes.volumeUnit !== 'm³'
                "
                >{{ inventoryItem.data.attributes.volumeUnit }}</span
              >
            </li>
          </ul> -->

          <DimensionsTableField
            :item="inventoryItem.data"
          /> </v-list-item-content
      ></v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
import DimensionsTableField from '@/components/DimensionsTableField.vue'
export default {
  components: {
    DimensionsTableField
  },
  mixins: [UnitsConversion],
  computed: {
    inventoryItem() {
      return this.$store.getters['inventoryItem/item']
    },
    itemName() {
      return this.inventoryItem?.data.attributes.name
    },
    inStock() {
      const stock = this.inventoryItem?.data.attributes.itemUnitsInStockCount
      return stock || 0
    },
    itemUnitsCount() {
      const stock = this.inventoryItem?.data.attributes.itemUnitsCount
      return stock || 0
    },
    itemUnitsInProcessCount() {
      const stock = this.inventoryItem?.data.attributes
        .itemUnitsProcessingPendingCount
      return stock || 0
    }
  }
}
</script>
