<template>
  <div v-if="!isLoading">
    <v-card flat>
      <v-card-title class="text-h6"
        ><v-icon left>mdi-pine-tree</v-icon>{{ $t('common.parent') }}
        {{ $t('common.details') }}
      </v-card-title>
      <v-list v-if="isAdmin">
        <v-list-item
          ><v-list-item-content class="font-weight-bold"
            >{{ $tc('purchase.name', 1) }}
          </v-list-item-content>
          <v-list-item-content>
            {{ incomingShipment || '-' }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          ><v-list-item-content class="font-weight-bold"
            >{{ $tc('lot.name', 1) }}
          </v-list-item-content>
          <v-list-item-content>
            {{ itemGroupDescription || '-' }}
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          ><v-list-item-content class="font-weight-bold"
            >{{ $tc('blockchain.registration', 1) }}
          </v-list-item-content>
          <v-list-item-content class="purple--text text--lighten-1">
            <span
              class="d-inline-block text-truncate"
              style="max-width: 600px;"
            >
              {{ blockchain || '-' }}
            </span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          ><v-list-item-content class="font-weight-bold"
            >{{ $t('inventory.categories.log') }}
          </v-list-item-content>
          <v-list-item-content>
            {{ resourceDescription || '-' }}
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <table v-if="inventoryItem" class="mt-3 mb-5 mx-3">
        <thead>
          <tr>
            <th
              v-for="(prop, i) in props"
              :key="i"
              class="grey--text text--darken-2 text-caption"
            >
              {{ $t(`item.${prop}`) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              v-for="(prop, j) in props"
              :key="j"
              class="grey--text text--darken-3"
            >
              {{
                (prop === 'specie'
                  ? $t(
                      `species.${inventoryItem.data.attributes.customAttributes[prop].text}`
                    )
                  : inventoryItem.data.attributes.customAttributes[prop]) || '-'
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </v-card>
  </div>
  <v-alert v-else-if="!isLoading" type="warning" text show>{{
    $t('alerts.noRecords')
  }}</v-alert>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    resource: {
      type: Object,
      default: null
    },
    itemGroup: {
      type: Object,
      default: null
    },
    purchaseOrder: {
      type: Object,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      props: ['origin', 'specie', 'marking', 'quality', 'category', 'type']
    }
  },
  computed: {
    blockchain() {
      const { blockchain } = this.resource?.data.attributes.customAttributes
      return blockchain
    },
    resourceDescription() {
      const { description } = this.resource?.data.attributes
      return description
    },
    itemGroupDescription() {
      return this.itemGroup?.data?.attributes.description
    },
    incomingShipment() {
      return this.purchaseOrder?.attributes.customAttributes.name
    },
    ...mapGetters({
      inventoryItem: 'inventoryItem/item',
      isAdmin: 'auth/isAdmin'
    })
  }
}
</script>
<style lang="scss" scoped>
table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}
td,
th {
  padding: 0.5rem;
  text-align: left;
}
</style>
